import request from './request.js'

export function orderGetAgents(params) {
    return request({
        url: 'agent/list4agent.do?',
        method: 'post',
        data: params
    })
}
export function GetApplyCheckList(params) {
    return request({
        url: 'agentApply/applyCheckList.do?',
        method: 'post',
        data: params
    })
}
export function GentApplyCheck(params) {
    return request({
        url: 'agentApply/check.do?',
        method: 'post',
        data: params
    })
}


//agentApplyDetail.vue
export function agentRebateDetail(params) {
    return request({
        url: 'agent/agentRebate/detailed.do?',
        method: 'post',
        data: params
    })
}
export function exportDetailed(params) {
    return request({
        url: 'agent/agentRebate/exportdetailedSync.do?',
        method: 'post',
        data: params
    })
}

//agentApply.vue
export function Permission(params) {
    return request({
        url: 'agent/agentRebate/permission.do?',
        method: 'post',
        data: params
    })
}
export function GetAgentApply(params) {
    return request({
        url: 'agentApply/apply.do?',
        method: 'post',
        data: params
    })
}

//widthdraw.vue
export function agentRebateCheck(params) {
    return request({
        url: 'agent/agentRebate/check.do?',
        method: 'post',
        data: params
    })
}
export function getCountMoney(params) {
    return request({
        url: 'agent/agentRebate/countMoney.do?',
        method: 'post',
        data: params
    })
}
export function agentRebateApply(params) {
    return request({
        url: 'agent/agentRebate/apply.do?',
        method: 'post',
        data: params
    })
}
export function moneyDetailSync(params) {
    return request({
        url: 'agent/agentRebate/moneyDetailSync.do?',
        method: 'post',
        data: params
    })
}

//agentRebate.vue
export function getAgentRebate(params) {
    return request({
        url: 'agent/agentRebate/list.do?',
        method: 'post',
        data: params
    })
}

//adminRebateList.vue
export function getAdmineAgent(params) {
    return request({
        url: 'agent/list4agentAll.do?',
        method: 'post',
        data: params
    })
}
export function getAdmineList(params) {
    return request({
        url: 'agent/agentRebate/admineList.do?',
        method: 'post',
        data: params
    })
}
export function limitExportCount(params) {
    return request({
        url: 'agent/agentRebate/admineListExportSync.do?',
        method: 'post',
        data: params
    })
}